// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';
import Counter from 'marketing/components/Counter';

// Utilities
import { getLastExpiredTimer } from 'utils/prizes';

// Data
import { raffle } from 'data/data.json';

// Styles
import styles from './styles.module.css';

export default data => {
  const {
    currentPrizeTotal,
    titleHeading,
    titleSubheading,
    pageLogo,
    imageAltText,
    pageHeading,
    pageSubheading,
    pageBannerText,
    pageBackgroundImage,
    ticketInformation,
    smallTextRules,
    timers
  } = data.pageContext.data;

  const { timeZone } = raffle;
  const { rulesPdf } = data.pageContext;

  const expiredTimer = getLastExpiredTimer(timers, timeZone);

  return (
    <LayoutContainer includeOrderTicketsCta includeWinnersLink={data.pageContext.winnersLink}>
      <div className="theme-background-primary">
        <div className={styles.layoutContainer}>
          <TitleBlock mainHeading={titleHeading} subHeading={titleSubheading} onThemeBackground />
          <div className={styles.divider} />
          <div
            className="u-full-width l-margin-bottom l-margin-top--40 u-center-canvas addon-page"
            style={pageBackgroundImage && { backgroundImage: `url(${pageBackgroundImage})` }}
          >
            <div className={styles.layoutContainer}>
              {pageLogo && (
                <img className="u-block u-margin-center" src={pageLogo} alt={imageAltText} />
              )}
              <div className="u-center-canvas">
                {pageHeading && <p className="h2 alt u-text-shadow">{pageHeading}</p>}
                <div className={styles.counterContainer}>
                  <Counter total={(expiredTimer && expiredTimer.value) || currentPrizeTotal} />
                </div>
                {pageBannerText && (
                  <p className="h3 u-standout-heading l-padding-bottom">{pageBannerText}</p>
                )}
                {pageSubheading && <p className="h4">{pageSubheading}</p>}
              </div>
            </div>
          </div>
          {ticketInformation && (
            <p className="h5 u-center-canvas l-padding-top u-no-margin">{ticketInformation}</p>
          )}
          {smallTextRules && (
            <p className="p1 u-center-canvas l-padding-top u-no-margin">{smallTextRules}</p>
          )}
          {rulesPdf && (
            <p className="p1 u-center-canvas l-padding-top u-no-margin">
              See
              {' '}
              <a className={styles.rules} href={rulesPdf}>
                Rules
              </a>
              {' '}
              for more information.
            </p>
          )}

          <div className={styles.divider} />
        </div>
      </div>
    </LayoutContainer>
  );
};
